<script>
import { uniqBy } from 'lodash'
import ProductMixin from '~/sites/shop/mixins/ProductMixin'

export default {
  extends: ProductMixin,
  computed: {
    variantsAvailabilityInWarehouses() {
      const disabledStockIds = [9]
      const warehouses = {}
      const defaultWarehouseShipper = this.$themeSettings.product.defaultWarehouseShipperCode
        ? this.$store.state.globals.shippers.find(
            shipper => shipper.code === this.$themeSettings.product.defaultWarehouseShipperCode,
          )
        : null
      this.product.variants.forEach(variant => {
        variant.warehouses.forEach(warehouse => {
          warehouse.shipper = defaultWarehouseShipper
        })
        warehouses[variant.id] = {
          online: variant.warehouses
            ? variant.warehouses.filter(w => w.virtual === false && w.stock > 0 && !disabledStockIds.includes(w.id))
            : [],
          virtual: variant.warehouses
            ? variant.warehouses.filter(w => w.virtual === true && w.stock > 0 && !disabledStockIds.includes(w.id))
            : [],
          allSorted: variant.warehouses
            ? this.mergeDuplicateWarehouses(variant.warehouses.filter(w => !disabledStockIds.includes(w.id)))
            : [],
        }
      })
      return warehouses
    },
  },
  methods: {
    mergeDuplicateWarehouses(warehouses) {
      let uniqWarehouses = JSON.parse(JSON.stringify(uniqBy(warehouses, 'id')))
      let stocksMapping = new Map()
      for (const w of warehouses) {
        if (typeof stocksMapping.get(w.id) !== 'undefined') {
          stocksMapping.set(w.id, stocksMapping.get(w.id) + w.stock)
        } else {
          stocksMapping.set(w.id, w.stock)
        }
      }
      for (const [id, stock] of stocksMapping) {
        uniqWarehouses.find(w => w.id === id).stock = stock
      }
      return uniqWarehouses.sort((a, b) => b.stock - a.stock)
    },
  },
}
</script>
