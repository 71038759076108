import { actions, getters, mutations, state as parentState } from '~/sites/shop/store/cart'
import { merge } from 'lodash'

const overloadState = {
  input: {
    complianceHeureka: true,
    complianceZbozi: true,
  },
}

const state = () => ({
  ...merge(parentState(), overloadState),
})

export { actions, state, mutations, getters }
